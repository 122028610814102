import React from 'react';
import { PlayIconContainer } from '@/components/Gameplay/PlayIcon/PlayIcon.styled';
import Icon from '@/images/Gameplay/PlayIcon.svg';

const PlayIcon = () => (
  <PlayIconContainer>
    <img src={Icon} alt="icon" />
  </PlayIconContainer>
);

export default PlayIcon;
