import styled from 'styled-components';
import RoadmapBackgroundImage from '@/images/Backgrounds/RoadmapBackground.png';
import RoadmapBackgroundImageSmall from '@/images/Backgrounds/smallRoadmapBackground.png';

export const BackgroundContainer = styled.div`
  position: relative;
`;

export const BackgroundImage = styled.div<{ isMobile: boolean }>`
  background: url(${(props) =>
      props.isMobile ? RoadmapBackgroundImageSmall : RoadmapBackgroundImage})
    no-repeat;
  background-size: 100% 100%;
  width: 100%;
  max-height: 1120px;
  height: 100%;
  position: absolute;
  top: 633px;
  z-index: -4;
  @media (max-width: 768px) {
    max-height: 1303px;
    background-size: 100% 100%;
    object-fit: contain;
    top: 350px;
    width: 100%;
    height: 100%;
    left: 0;
  }
`;

export const BlackoutFirst = styled.div`
  height: 235px;
  width: 100%;
  background: linear-gradient(0deg, rgba(22, 25, 33, 0) 0%, #161822 100%);
  position: absolute;
  z-index: -3;
`;

export const BlackoutSecond = styled.div`
  height: 235px;
  width: 100%;
  background: linear-gradient(180deg, rgba(22, 25, 33, 0) 0%, #161822 100%);
  position: absolute;
  bottom: 0;
  z-index: -8;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const BlackoutThird = styled.div`
  position: absolute;
  width: 100%;
  height: 730px;
  bottom: -390px;
  z-index: -6;
  background-size: 100% 100%;
  background: linear-gradient(180deg, #0d1018 0%, #171a22 100%) no-repeat;
  opacity: 0.2;
  @media (max-width: 768px) {
    display: none;
  }
`;
