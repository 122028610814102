import styled from 'styled-components';
import PlayIcon from '@/images/CustomPlayer/Player.svg';
import PauseIcon from '@/images/CustomPlayer/PauseIcon.svg';

export const PopupContainer = styled.div`
  z-index: 30;
  width: 100%;
  height: 100vh;
  position: fixed;
  backdrop-filter: blur(50px);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const TrailerPopup = styled.div`
  width: 100%;
  max-width: 1020px;
  max-height: 560px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.black};
  padding: 25px;
  border-radius: 30px;
`;
export const TrailerPopupContent = styled.div`
  width: 100%;
  height: 100%;
`;
export const TrailerPopupInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;
export const TrailerTitle = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;
export const TrailerDescription = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => props.theme.colors.gray};
  margin-top: 5px;
`;
export const PlayerWrapper = styled.div`
  width: 100%;
  height: 450px;
  position: relative;
  border-radius: 20px;
  & .customPlayer {
    border-radius: 30px;
  }
`;

export const ControlsBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  height: 60px;
  z-index: 10;
  width: 100%;
  bottom: 0;
`;

export const ProgressBarBox = styled.div`
  position: absolute;
  width: 100%;
  bottom: 50px;
  display: flex;
  justify-content: center;
`;

export const MainProgressBarFill = styled.div<{
  value: string;
  isFullScreen: boolean;
}>`
  width: ${(props) => props.value}%;
  background: ${(props) => props.theme.colors.pink};
  position: absolute;
  height: 3px;
  z-index: 1;
  bottom: 0;
  left: 0;
  ${(props) => {
    if (!props.isFullScreen) {
      return 'left: 10px;';
    }
    return null;
  }}
  ${(props) => {
    if (!props.isFullScreen) {
      return 'max-width: 945px;';
    }
    return null;
  }}
`;

export const ProgressBar = styled.input<{ isFullScreen: boolean }>`
  outline: 0;
  border: 0;
  width: 100%;
  transition: box-shadow 0.2s ease-in-out;
  position: absolute;
  height: 3px;
  appearance: none;
  background-color: rgba(255, 255, 255, 0.3);
  bottom: 0;
  &::-webkit-slider-thumb {
    appearance: none;
    height: 20px;
    z-index: 2;
    width: 3px;
    background: none;
  }
}

${(props) => {
  if (!props.isFullScreen) {
    return 'max-width: 940px;';
  }
  return 'max-width: 100%;';
}}
`;

export const ControlsBottom = styled.div``;

export const Play = styled.div<{ playing: boolean }>`
  width: 14px;
  height: 20px;
  background: ${(props) =>
    !props.playing ? `url(${PlayIcon})` : `url(${PauseIcon})`};
  position: absolute;
  left: 25px;
  bottom: 15px;
  cursor: pointer;
`;

export const VolumeBox = styled.div`
  position: absolute;
  bottom: 17.07px;
  display: flex;
  align-items: center;
  left: 69px;
`;

export const VolumeIcons = styled.div<{ value: string }>`
  display: flex;
  align-items: center;
  margin-right: 11px;
  ${(props) => {
    if (Number(props.value) === 0) {
      return 'margin-right: 20px;';
    }
    return null;
  }}
`;
export const VolumeIconFirst = styled.img`
  margin-right: 4.54px;
`;
export const VolumeIconSecond = styled.img<{ value: string }>`
  ${(props) => {
    if (Number(props.value) === 0) {
      return 'display: none;';
    }
    return null;
  }}
`;

export const VolumeControlBox = styled.div`
  width: 60px;
  position: relative;
  height: 15px;
  display: flex;
  align-items: center;
`;
export const VolumeControl = styled.input`
  outline: 0;
  border: 0;
  width: 100%;
  max-width: 100%;
  transition: box-shadow 0.2s ease-in-out;
  height: 3px;
  appearance: none;
  cursor: pointer !important;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 2;

  &::-webkit-slider-runnable-track {
    appearance: none;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    height: 10px;
    width: 10px;
    background-color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    border-radius: 50%;
    z-index: 10;
  }
`;
export const ProgressBarFill = styled.div<{ value: string }>`
  width: ${(props) => props.value}%;
  height: 3px;
  background: ${(props) => props.theme.colors.white};
  position: absolute;
  top: 40%;
  cursor: pointer;
`;
export const TimeBox = styled.div`
  position: absolute;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  left: 190px;
  bottom: 17px;
`;
export const FullScreen = styled.img`
  position: absolute;
  right: 27.5px;
  bottom: 17px;
  cursor: pointer;
`;

export const TrailerPopupCaption = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 70px;
`;
