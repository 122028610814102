import React, { useEffect, useState } from 'react';
import Container from '@/components/Container';
import {
  AchievementBox,
  AchievementCaption,
  AchievementsContainer,
  AchievementTitle,
  LinkedInIcon,
  LinkedInLink,
  Slash,
  TeamContent,
  TeamHeader,
  TeamHeaderDescription,
  TeamHeaderDescriptionBox,
  TeamHeaderTitle,
  TeamHeaderTitleBox,
  TeamMemberBlock,
  TeamMemberBlockContent,
  TeamMemberBlockContentDescription,
  TeamMemberBlockContentName,
  TeamMemberBlockContentProfession,
  TeamMemberBlockImage,
  TeamTitleVector,
  PopupDescription,
  PopupHeader,
  PopupTitle,
  ModalContent,
} from '@/components/Team/Team.styled';
import RightVector from '@/images/Vector-right.svg';
import LeftVector from '@/images/Vector-left.svg';
import RightVectorSmall from '@/images/VectorRightSmall.svg';
import LeftVectorSmall from '@/images/VectorLeftSmall.svg';
import TeamMember from '@/images/Team/TeamMember.png';
import TeamMember4 from '@/images/Team/TeamMember4.png';
import TeamMember5 from '@/images/Team/TeamMember5.png';
import TeamMember6 from '@/images/Team/TeamMember6.png';
import LinkedIn from '@/images/Team/linkedin.svg';
import { LinkedInSocial } from '@/utils/constants';
import Popup from '@/components/Popup/Popup';
import { useIsMobile } from '@/hooks/useMobile';

const TeamMembers = [
  {
    linkedIn: LinkedInSocial.VASILI_VEKA,
    name: 'Vasili Veka',
    profession: 'CEO',
    description:
      'Entrepreneur, visionary; Altwolf Software founder and managing partner.',
    image: TeamMember6,
  },
  {
    linkedIn: LinkedInSocial.MIKHAIL_PAKHOMAU,
    name: 'Mikhail Pakhomau',
    profession: 'COO',
    description:
      '15+ years of experience in leadership positions, including 5 years as director of a game studio.',
    image: TeamMember5,
  },
  {
    linkedIn: LinkedInSocial.DMITRY_SUVOROV,
    name: 'Dmitry Suvorov',
    profession: 'CMO',
    description:
      '20+ years spatial data process, spatial analysis, statistics, visualization experience (GIS, VR/AR).',
    image: TeamMember,
  },
  {
    linkedIn: LinkedInSocial.SERGEY_SHILO,
    name: 'Sergey Shilo',
    profession: 'CTO',
    description: '13+ years experience in the commercial development sector.',
    image: TeamMember4,
  },
];

const Team = () => {
  const [isPopupOpened, setPopupOpened] = useState<boolean>(false);
  const [popupText, setPopupText] = useState<string>('');
  const [popupTitle, setPopupTitle] = useState<string>('');

  const isMobile = useIsMobile();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePopupOpened = (text: string, title: string) => {
    setPopupTitle(title);
    setPopupText(text);
    setPopupOpened(!isPopupOpened);
  };

  useEffect(() => {
    if (isPopupOpened) {
      document.body.style.overflow = 'hidden';
    }
    if (!isPopupOpened) {
      document.body.style.overflow = 'auto';
    }
  }, [isPopupOpened]);

  return (
    <Container id="team">
      <TeamHeader>
        <TeamHeaderTitleBox>
          <TeamTitleVector src={isMobile ? LeftVectorSmall : LeftVector} />
          <TeamHeaderTitle>Team</TeamHeaderTitle>
          <TeamTitleVector src={isMobile ? RightVectorSmall : RightVector} />
        </TeamHeaderTitleBox>
        <TeamHeaderDescriptionBox>
          <TeamHeaderDescription>
            We are, first of all, passionate gamers. We build games and we have
            fun doing it. We love the challenge of creating games, from the
            initial concept through to aligning the final pixels. To date, the
            SkyBreakers.io development team consists of ten members.
          </TeamHeaderDescription>
        </TeamHeaderDescriptionBox>
        <AchievementsContainer>
          <AchievementBox>
            <AchievementTitle>16 yr</AchievementTitle>
            <AchievementCaption>Experience</AchievementCaption>
          </AchievementBox>
          <Slash />
          <AchievementBox>
            <AchievementTitle>50+</AchievementTitle>
            <AchievementCaption>Developers</AchievementCaption>
          </AchievementBox>
          <Slash />
          <AchievementBox>
            <AchievementTitle>30+ mln</AchievementTitle>
            <AchievementCaption>Satisfied players</AchievementCaption>
          </AchievementBox>
        </AchievementsContainer>
      </TeamHeader>
      <TeamContent>
        {TeamMembers.map((member, idx) => (
          <TeamMemberBlock key={`${member.linkedIn}-${idx}`}>
            <TeamMemberBlockImage src={member.image} />
            <TeamMemberBlockContent>
              <TeamMemberBlockContentName>
                {member.name}
                <LinkedInLink target="_blank" href={member.linkedIn}>
                  <LinkedInIcon src={LinkedIn} />
                </LinkedInLink>
              </TeamMemberBlockContentName>
              <TeamMemberBlockContentProfession>
                {member.profession}
              </TeamMemberBlockContentProfession>
              <TeamMemberBlockContentDescription>
                {member.description}{' '}
              </TeamMemberBlockContentDescription>
            </TeamMemberBlockContent>
          </TeamMemberBlock>
        ))}
      </TeamContent>
      {isPopupOpened && (
        <Popup setOpened={setPopupOpened}>
          <ModalContent>
            <PopupHeader>
              <PopupTitle>{popupTitle}</PopupTitle>
            </PopupHeader>
            <PopupDescription>{popupText}</PopupDescription>
          </ModalContent>
        </Popup>
      )}
    </Container>
  );
};

export default Team;
