import React, { FC } from 'react';
import {
  CloseBtn,
  CloseBtnBox,
  PopupBox,
  PopupContainer,
} from '@/components/Popup/Popup.styled';

interface IPopupProps {
  setOpened: (isOpened?: boolean) => void;
  children: any;
}

const Popup: FC<IPopupProps> = ({ children, setOpened }) => {
  const handleClose = () => {
    setOpened(false);
  };

  return (
    <PopupBox onClick={handleClose}>
      <PopupContainer onClick={(e) => e.stopPropagation()}>
        <CloseBtnBox onClick={handleClose}>
          <CloseBtn />
        </CloseBtnBox>
        {children}
      </PopupContainer>
    </PopupBox>
  );
};

export default Popup;
