import React from 'react';

import { ThemeProvider } from '@/themes/theme';
import { Helmet } from 'react-helmet';
import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import favicon from '@/images/favicon.ico';
import Header from '@/components/Header';
import Intro from '@/components/Intro/Intro';
import Gameplay from '@/components/Gameplay/Gameplay';
import Helicopters from '@/components/Helicopters/Helicopters';
import AmmunitionTypes from '@/components/UI/AmmunitionTypes/AmmunitionTypes';
import GameReady from '@/components/GameReady/GameReady';
import Team from '@/components/Team/Team';
import Projects from '@/components/Projects/Projects';
import Roadmap from '@/components/Roadmap/Roadmap';
import News from '@/components/News/News';
import Footer from '@/components/Footer';
import HelicopterBackground from '@/components/HelicopterBackground/HelicopterBackground';
import VectorBackground from '@/components/VectorBackground/VectorBackground';
import GameReadyBackground from '@/components/GameReadyBackground/GameReadyBackground';
import { ParallaxProvider } from 'react-scroll-parallax';
import RoadmapBackground from '@/components/RoadmapBackground/RoadmapBackground';

const Home = () => (
  <ThemeProvider>
    <ParallaxProvider>
      <Helmet>
        <title>Sky Breakers</title>
        <link rel="icon" href={favicon} />
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
      </Helmet>
      <GlobalStyle />
      <Wrapper>
        <HelicopterBackground>
          <Header />
          <Intro />
          <VectorBackground>
            <Gameplay />
          </VectorBackground>
        </HelicopterBackground>
        <Helicopters />
        <GameReadyBackground>
          <AmmunitionTypes />
          <GameReady />
          <Team />
        </GameReadyBackground>
        <RoadmapBackground>
          <Projects />
          <Roadmap />
        </RoadmapBackground>
        <News />
        <Footer />
      </Wrapper>
    </ParallaxProvider>
  </ThemeProvider>
);

export default Home;
