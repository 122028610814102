import styled from 'styled-components';

interface IColor {
  color: string;
}

export const RoadmapContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  @media (max-width: 767px) {
    margin-top: 100px;
  }
`;
export const RoadmapHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  @media (max-width: 767px) {
    margin-top: 100px;
  }
`;
export const RoadmapHeaderTitleBox = styled.div``;
export const RoadmapHeaderTitle = styled.h1`
  font-size: 80px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 30px;
  @media (max-width: 767px) {
    font-size: 50px;
    margin: 0 5px;
  }
`;
export const RoadmapHeaderImage = styled.img``;
export const RoadmapContent = styled.div`
  height: 680px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 1280px) {
    padding: 0 20px;
  }
  @media (max-width: 980px) {
    height: auto;
  }
  @media (max-width: 767px) {
    height: auto;
  }
`;

export const MilestoneBox = styled.div<{ lineBox: boolean }>`
  position: relative;
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: ${(props) => (props.lineBox ? 0 : 55)}px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;

export const MilestoneBox2 = styled.div<{ lineBox: boolean }>`
  position: relative;
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: ${(props) => (props.lineBox ? 0 : 55)}px;
  flex-wrap: wrap;
  padding-left: 200px;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;

export const MilestoneLineBox = styled.div`
  position: relative;
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const MilestoneLineItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20%;
  max-width: 180px;
`;

export const MilestoneGroupItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => (props.isMobile ? 20 : 30)}%;
  max-width: ${(props) => (props.isMobile ? 180 : 410)}px; 
  @media (max-width: 980px) {
    width: 30%;
    max-width: none;
    justify-content: flex-end;
    flex-direction: row-reverse;
    position: relative;
    margin: 10px 0 30px 0;
    &:nth-child(2n) {
      & > div:first-child {
        position: static;
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    min-height: 200px;
    align-items: center;

    &:nth-child(1){
      order 1;
    }

    &:nth-child(2){
      order 2;
    }

    &:nth-child(3){
      order 3;
    }

    &:nth-child(4){
      order 4;
    }

    &:nth-child(5){
      order 5;
    }
  }
`;

export const MilestoneGroupTitle = styled.div`
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 5px;
  color: ${(props: IColor) => (props.color === 'pink' ? '#C72D92' : '#486DD3')};
`;

export const MilestoneGroup = styled.div`
  @media (max-width: 980px) {
    margin-left: 60px;
  }
`;

export const CheckboxContainer = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;
export const CheckboxLabel = styled.span`
  margin-left: 5px;
  font-size: 15px;
  line-height: 20.25px;
  flex: 1;
`;
export const RoadmapLine = styled.div`
  position: absolute;
  bottom: 0;
  left: calc((100vw - 100%) / -2);
  width: 100vw;
  height: 10px;
  background: rgba(121, 128, 148, 0.3);
  z-index: -5;
  @media (max-width: 980px) {
    width: 10px;
    height: calc(100% - 50px);
    left: 21px;
    top: 20px;
    border-radius: 10px;
  }
`;
export const RoadmapLineInnerDash = styled.div`
  width: 100%;
  margin-top: 40px;
  background: linear-gradient(90deg, #486dd3 0%, #c72d92 100%);
  height: 10px;
  border-radius: 10px;
  @media (max-width: 768px) {
    transform: rotate(90deg);
    max-width: 180px;
    position: absolute;
    left: -64px;
    top: 0;
    margin: 90px 0 0;
  }
`;
