import React from 'react';
import {
  GameplayContainer,
  GameplayContent,
  GameplayContentBox,
  GameplayHeader,
  GameplayHeaderImage,
  GameplayHeaderTypography,
  GameplayHeaderDescriptionBox,
  GameplayHeaderDescription,
  GameplayName,
  PlayerWrapper,
  VideoTime,
} from '@/components/Gameplay/Gameplay.styled';
import VectorLeft from '@/images/Vector-left.svg';
import VectorRight from '@/images/Vector-right.svg';
import VectorLeftSmall from '@/images/VectorLeftSmall.svg';
import VectorRightSmall from '@/images/VectorRightSmall.svg';
import ReactPlayer from 'react-player';
import PlayIcon from '@/components/Gameplay/PlayIcon/PlayIcon';
import { useIsMobile } from '@/hooks/useMobile';
import GamePlayImage1 from '@/images/Gameplay/GamePlay1_600_350.png';
import GamePlayImage2 from '@/images/Gameplay/GamePlay2_600_350.png';

const Gameplay = () => {
  const isMobile = useIsMobile();

  return (
    <GameplayContainer id="gameplay">
      <GameplayHeader>
        <GameplayHeaderImage
          src={isMobile ? VectorLeftSmall : VectorLeft}
          alt=""
        />
        <GameplayHeaderTypography>Gameplay</GameplayHeaderTypography>
        <GameplayHeaderImage
          src={isMobile ? VectorRightSmall : VectorRight}
          alt=""
        />
      </GameplayHeader>
      <GameplayHeaderDescriptionBox>
        <GameplayHeaderDescription>
          Take to the skies and discover your passion for air combat.
          <br />
          Fill your life with the sound of roaring rotors and the sight of
          falling enemies.
          <br />
          Become the best pilot, improve your helicopters, win battles.
        </GameplayHeaderDescription>
      </GameplayHeaderDescriptionBox>
      <GameplayContent>
        <GameplayContentBox>
          <PlayerWrapper>
            <ReactPlayer
              controls
              url="https://youtu.be/onjwulCLFKk&t=1"
              className="video"
              playIcon={<PlayIcon />}
              light={GamePlayImage1}
              width="100%"
              height="100%"
            />
            <VideoTime>1:33</VideoTime>
          </PlayerWrapper>
          <GameplayName>Build a superior helicopter fleet</GameplayName>
        </GameplayContentBox>
        <GameplayContentBox>
          <PlayerWrapper>
            <ReactPlayer
              controls
              url="https://youtu.be/qvu_GKUgqH0"
              className="video"
              playIcon={<PlayIcon />}
              light={GamePlayImage2}
              width="100%"
              height="100%"
            />
            <VideoTime>0:47</VideoTime>
          </PlayerWrapper>
          <GameplayName>
            Dominate in large scale helicopter battles
          </GameplayName>
        </GameplayContentBox>
      </GameplayContent>
    </GameplayContainer>
  );
};
export default Gameplay;
