import React, { useEffect, useState } from 'react';
import { useIsMobile } from '@/hooks/useMobile';
import LeftVector from '@/images/Vector-left.svg';
import RightVector from '@/images/Vector-right.svg';
import LeftVectorSmall from '@/images/VectorLeftSmall.svg';
import RightVectorSmall from '@/images/VectorRightSmall.svg';
import CustomCheckBox from '@/components/UI/CustomCheckBox/CustomCheckBox';
import * as styles from './Roadmap.styled';
import items from './items';

const Roadmap = () => {
  const isMobile = useIsMobile();
  const [isASmallScreenRoadmap, setIsSmallScreenRoadmap] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 980) {
      setIsSmallScreenRoadmap(true);
    } else {
      setIsSmallScreenRoadmap(false);
    }
  };

  useEffect(() => {
    setIsSmallScreenRoadmap(document.body.offsetWidth < 980);
    window.addEventListener('resize', handleResize);
  });

  const [items1, setItems1] = useState([items[0], items[2]]);
  const [items2, setItems2] = useState([items[1], items[3]]);

  useEffect(() => {
    setItems1(isASmallScreenRoadmap ? items : [items[0], items[2]]);
    setItems2(isASmallScreenRoadmap ? [] : [items[1], items[3]]);
  }, [isASmallScreenRoadmap]);

  return (
    <>
      <styles.RoadmapContainer id="roadmap">
        <styles.RoadmapHeader>
          <styles.RoadmapHeaderImage
            src={isMobile ? LeftVectorSmall : LeftVector}
          />
          <styles.RoadmapHeaderTitle>Roadmap</styles.RoadmapHeaderTitle>
          <styles.RoadmapHeaderImage
            src={isMobile ? RightVectorSmall : RightVector}
          />
        </styles.RoadmapHeader>
      </styles.RoadmapContainer>
      <styles.RoadmapContent>
        {isMobile ? (
          <styles.MilestoneBox>
            {items.map((item, idx) => (
              <styles.MilestoneGroupItem key={`${item.title}-${idx}`}>
                <styles.MilestoneGroup>
                  <styles.MilestoneGroupTitle color={item.colorMobile}>
                    {item.title}
                  </styles.MilestoneGroupTitle>
                  {item.checkboxes.map((checkbox, i) => (
                    <styles.CheckboxContainer key={`${checkbox.label}-${i}`}>
                      <CustomCheckBox
                        checked={checkbox.checked}
                        color={item.colorMobile}
                        disabled
                      />
                      <styles.CheckboxLabel>
                        {checkbox.label}
                      </styles.CheckboxLabel>
                    </styles.CheckboxContainer>
                  ))}
                </styles.MilestoneGroup>
                <styles.RoadmapLineInnerDash />
              </styles.MilestoneGroupItem>
            ))}
          </styles.MilestoneBox>
        ) : (
          <>
            <styles.MilestoneBox lineBox>
              {items1.map((item, idx) => (
                <styles.MilestoneGroupItem
                  type="top"
                  key={`${item.title}-${idx}`}
                >
                  <styles.MilestoneGroup>
                    <styles.MilestoneGroupTitle color={item.color}>
                      {item.title}
                    </styles.MilestoneGroupTitle>
                    {item.checkboxes.map((checkbox, i) => (
                      <styles.CheckboxContainer key={`${checkbox.label}-${i}`}>
                        <CustomCheckBox
                          checked={checkbox.checked}
                          color={item.color}
                          disabled
                        />
                        <styles.CheckboxLabel>
                          {checkbox.label}
                        </styles.CheckboxLabel>
                      </styles.CheckboxContainer>
                    ))}
                  </styles.MilestoneGroup>
                </styles.MilestoneGroupItem>
              ))}
            </styles.MilestoneBox>
            <styles.MilestoneLineBox>
              {!isASmallScreenRoadmap &&
                items.map((itemLien, lineIdx) => (
                  <styles.MilestoneLineItem key={`${lineIdx}`}>
                    <styles.RoadmapLineInnerDash />
                  </styles.MilestoneLineItem>
                ))}
              <styles.RoadmapLine />
            </styles.MilestoneLineBox>
            <styles.MilestoneBox2>
              {items2.map((item, idx) => (
                <styles.MilestoneGroupItem
                  type="bottom"
                  key={`${item.title}-${idx}`}
                >
                  <styles.MilestoneGroup>
                    <styles.MilestoneGroupTitle color={item.color}>
                      {item.title}
                    </styles.MilestoneGroupTitle>
                    {item.checkboxes.map((checkbox, i) => (
                      <styles.CheckboxContainer key={`${checkbox.label}-${i}`}>
                        <CustomCheckBox
                          checked={checkbox.checked}
                          color={item.color}
                          disabled
                        />
                        <styles.CheckboxLabel>
                          {checkbox.label}
                        </styles.CheckboxLabel>
                      </styles.CheckboxContainer>
                    ))}
                  </styles.MilestoneGroup>
                </styles.MilestoneGroupItem>
              ))}
            </styles.MilestoneBox2>
          </>
        )}
      </styles.RoadmapContent>
    </>
  );
};

export default Roadmap;
