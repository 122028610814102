import styled from 'styled-components';

export const ProjectContainer = styled.div`
  margin-top: 120px;
`;

export const ProjectHeader = styled.div`
  display: flex;
  justify-content: center;
`;
export const ProjectHeaderTitle = styled.h2`
  font-size: 60px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    font-size: 30px;
  }
`;

export const ProjectContent = styled.div`
  min-width: 0;
  max-height: 500px;
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Slide = styled.div`
  position: relative;
`;

export const SlideInfo = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 320px;
  left: 30px;
  height: 150px !important;
  max-width: 93%;
  background: rgba(10, 12, 19, 0.8);
  padding-left: 48px;
  padding-top: 26px;
  padding-bottom: 31px;
  @media (max-width: 767px) {
    position: static;
    margin-left: 5px;
    max-width: 310px;
    padding: 0;
    display: block !important;
    background: none;
    height: 300px;
  }
`;

export const SlideTitle = styled.span`
  font-size: 30px;
  line-height: 36px;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20.25px;
    margin-top: 10px;
  }
`;

export const SlideDescription = styled.span`
  font-size: 20px;
  line-height: 24px;
  margin-top: 14px;
  max-width: 747px;
  @media (max-width: 767px) {
    color: ${(props) => props.theme.colors.gray};
    font-size: 13px;
    line-height: 17.55px;
    margin-top: 3px;
  }
`;
