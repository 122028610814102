import styled from 'styled-components';

export const PlayIconContainer = styled.nav`
  padding: 0;
  width: 70px;
  height: 70px;
  background: rgba(23, 26, 34, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 100%;
  & {
    img {
      margin-top: 30%;
      margin-left: 40%;
    }
  }
  &:hover {
    background: rgba(23, 26, 34, 0.8);
  }
`;
