import React from 'react';
import Slider from 'react-slick';
import {
  ComingSoonBtn,
  DescriptionBox,
  HelicoptersBox,
  HelicoptersContent,
  HelicoptersContentMobile,
  HelicoptersHeader,
  HelicoptersHeaderDescription,
  HelicoptersHeaderTitle,
  SliderBlock,
  SliderBlockContent,
  SliderBlockDescription,
  SliderBlockImage,
  SliderBlockTitle,
  SliderContentWrapper,
} from '@/components/Helicopters/Helicopters.styled';
import Container from '@/components/Container';
import HelicopterImage1 from '@/images/Helicopters/UH-1/Uh-1_brutal_350_200.png';
import HelicopterImage2 from '@/images/Helicopters/MD-902/Md-902_brutal_350_200.png';
import HelicopterImage3 from '@/images/Helicopters/NH-90/Nh-90_hentai_350_200.png';
import HelicopterImage4 from '@/images/Helicopters/Z-11/Z-11wb_happy_350_200.png';
import HelicopterImage5 from '@/images/Helicopters/AH-64/Ah-64a_apache_brutal_350_200.png';
import HelicopterImage6 from '@/images/Helicopters/RAH-66/Rah-66_happy_350_200.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../News/slider.css';
import settings from '../News/sliderSettings';

const items = [
  {
    title: 'UH-1',
    description:
      'Bell UH-1 Iroquois, commonly known as the "Huey," was a multipurpose utility helicopter famous for its widespread use.',
    image: HelicopterImage1,
    helipedia:
      'https://skybreakers-io.gitbook.io/skybreakers.io/skybreakers-helipedia/uh-1',
  },
  {
    title: 'MD902',
    description:
      'MD902 Explorer is a multipurpose helicopter that sets the standard in performance, affordability, dependability, and safety',
    image: HelicopterImage2,
    helipedia:
      'https://skybreakers-io.gitbook.io/skybreakers.io/skybreakers-helipedia/md-902-explorer',
  },
  {
    title: 'NH90',
    description:
      'NH90 military helicopter is a modern, multi-role rotorcraft designed to meet the most stringent NATO standards.',
    image: HelicopterImage3,
    helipedia:
      'https://skybreakers-io.gitbook.io/skybreakers.io/skybreakers-helipedia/nhindustries-nh90',
  },
  {
    title: 'CAIC Z-11',
    description:
      'Chinese medium attack helicopter developed for the PLA Ground Force.',
    image: HelicopterImage4,
    helipedia:
      'https://skybreakers-io.gitbook.io/skybreakers.io/skybreakers-helipedia/changhe-z-11',
  },
  {
    title: 'AH-64A',
    description:
      'AH-64A is an upgraded version, also known as the Longbow Apache, witted new fire control radar.',
    image: HelicopterImage5,
    helipedia:
      'https://skybreakers-io.gitbook.io/skybreakers.io/skybreakers-helipedia/ah-64a',
  },
  {
    title: 'RAH-66 Comanche',
    description:
      'Light Helicopter Experimental (LHX) program to replace UH-1, AH-1, OH-6, and OH-58 helicopters.',
    image: HelicopterImage6,
    helipedia:
      'https://skybreakers-io.gitbook.io/skybreakers.io/skybreakers-helipedia/assault-helicopters/rah-66',
  },
];

const handleHelicopter = (link: string) => {
  if (typeof window !== 'undefined') {
    const a = document.createElement('a');
    a.setAttribute('href', link);
    a.setAttribute('target', '_blank');
    a.click();
    a.remove();
  }
};

const Helicopters = () => (
  <Container id="helicopters">
    <HelicoptersBox>
      <HelicoptersHeader>
        <HelicoptersHeaderTitle>
          50+ Unique real-world-based helicopters
        </HelicoptersHeaderTitle>
        <DescriptionBox>
          <HelicoptersHeaderDescription>
            Select from various types of helicopters:
            <br />
            reconnaissance (fast and maneuverable), fire support (armed to the
            teeth), and assault (exceptionally durable). Configure them by
            increasing their combat capabilities, to accumulate experience for
            selected characters, and earn in-game money.
          </HelicoptersHeaderDescription>
        </DescriptionBox>
      </HelicoptersHeader>
      <HelicoptersContent>
        <Slider {...settings}>
          {items.map((item, idx) => (
            <div key={`${item.title}-${idx}`}>
              <SliderBlock onClick={() => handleHelicopter(item.helipedia)}>
                <SliderBlockImage src={item.image} />
                <SliderContentWrapper>
                  <SliderBlockTitle>{item.title}</SliderBlockTitle>
                  <SliderBlockDescription>
                    {item.description}
                  </SliderBlockDescription>
                  <ComingSoonBtn>Coming soon</ComingSoonBtn>
                </SliderContentWrapper>
              </SliderBlock>
            </div>
          ))}
        </Slider>
      </HelicoptersContent>
      <HelicoptersContentMobile>
        {items
          .filter((item, idx) => idx < 2)
          .map((item, idx) => (
            <SliderBlock
              key={`${item.title}-${idx}`}
              onClick={() => handleHelicopter(item.helipedia)}
            >
              <SliderBlockContent>
                <SliderBlockImage src={item.image} />
                <SliderContentWrapper>
                  <SliderBlockTitle>{item.title}</SliderBlockTitle>
                  <SliderBlockDescription>
                    {item.description}
                  </SliderBlockDescription>
                  <ComingSoonBtn>Coming soon</ComingSoonBtn>
                </SliderContentWrapper>
              </SliderBlockContent>
            </SliderBlock>
          ))}
      </HelicoptersContentMobile>
    </HelicoptersBox>
  </Container>
);

export default Helicopters;
