import React from 'react';
import Slider from 'react-slick';
import * as styles from '@/components/News/News.styled';
import ImageNews1 from '@/images/News/News1_350_200.png';
import ImageNews2 from '@/images/News/News2_350_200.png';
import ImageNews3 from '@/images/News/News3_350_200.png';
import ImageNews4 from '@/images/News/News4_350_200.png';
import settings from './sliderSettings';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';

const items = [
  {
    title:
      'Interview with Vasili Veko — The Founder Of Skybreakers (ex. C.H.A.O.S)',
    description:
      'If you are interested in helicopter games, you probably have heard of the game called C.H.A.O.S.',
    cardImage: ImageNews4,
    link: 'https://medium.com/@SkyBreakers.io/interview-with-vasili-veko-the-founder-of-skybreakers-ex-c-h-a-o-s-906afd70c5f0',
  },
  {
    title: 'The Team Of Skybreakers Is The Best',
    description:
      'Ever wondered what you could do with ten dedicated professionals? You could do a lot, but not as much as the team of Skybreakers.',
    cardImage: ImageNews3,
    link: 'https://medium.com/@SkyBreakers.io/why-the-team-of-skybreakers-is-the-best-among-simulators-73cea0e86606',
  },
  {
    title:
      'This Realistic Helicopter Simulator is Better Than You Think — Skybreakers',
    description:
      'Skybreakers is a mix of arcade simulator, shooter, and role-playing games.',
    cardImage: ImageNews2,
    link: 'https://medium.com/@SkyBreakers.io/this-realistic-helicopter-simulator-is-better-than-you-think-skybreakers-33bdecc9c6d4',
  },
  {
    title: 'Skybreakers Is Putting You Into The Seat Of A Helicopter Pilot',
    description:
      'Have you ever imagined how it feels to operate a helicopter ?',
    cardImage: ImageNews1,
    link: 'https://medium.com/@SkyBreakers.io/skybreakers-is-putting-you-into-the-seat-of-a-helicopter-pilot-c3483c8521b1',
  },
];

const handleNews = (link: string) => {
  if (typeof window !== 'undefined') {
    const a = document.createElement('a');
    a.setAttribute('href', link);
    a.setAttribute('target', '_blank');
    a.click();
    a.remove();
  }
};

const News = () => (
  <styles.NewsContainer>
    <styles.Background />
    <styles.NewsHeader>
      <styles.NewsLink
        href="https://medium.com/@skybreakers.io"
        target="_blank"
      >
        <styles.NewsTitle>News</styles.NewsTitle>
      </styles.NewsLink>
    </styles.NewsHeader>
    <styles.NewsContent>
      <Slider {...settings}>
        {items.map((item, idx) => (
          <div key={`${item.title}-${idx}`}>
            <styles.NewsCard onClick={() => handleNews(item.link)}>
              <styles.NewsCardImage src={item.cardImage} />
              <styles.NewsCardTitle>{item.title}</styles.NewsCardTitle>
              <styles.NewsCardDescription>
                {item.description}{' '}
                {item.link ? (
                  <styles.NewsCardMoreLink target="_blank" href={item.link}>
                    More..
                  </styles.NewsCardMoreLink>
                ) : (
                  ''
                )}
              </styles.NewsCardDescription>
            </styles.NewsCard>
          </div>
        ))}
      </Slider>
    </styles.NewsContent>
  </styles.NewsContainer>
);

export default News;
