import styled from 'styled-components';

export const NewsContainer = styled.div`
  padding: 120px 0 168px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 768px) {
    padding: 50px 0 107px;
  }
`;
export const Background = styled.div`
  background: linear-gradient(180deg, rgba(22, 25, 33, 0) 0%, #171a22 100%);
  height: 235px;
  width: 100%;
  position: absolute;
  top: -235px;
  z-index: -5;
`;
export const NewsHeader = styled.div``;
export const NewsTitle = styled.h2`
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;
export const NewsContent = styled.div`
  max-width: 1230px;
  width: 90%;
  margin: 0 auto;
  @media (max-width: 850px) {
    margin: 0 0px;
    width: 100%;
  }
  @media (max-width: 600px) {
    margin: 0 10px;
  }
`;
export const NewsCard = styled.div`
  max-width: 380px;
  margin: 40px 10px;
  padding: 20px 20px 30px 20px;
  display: flex;
  min-height: 380px;
  flex-direction: column;
  background: linear-gradient(#0a0c13, #0a0c13) padding-box,
    linear-gradient(180deg, #486dd3 0%, #0d1018 100%) border-box;
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
  border-radius: 20px;
  cursor: pointer;
  transition: all ease-out 1s;
  &:hover {
    background: linear-gradient(#0a0c13, #0a0c13) padding-box,
      linear-gradient(270deg, #5766cc 0%, #9c43a8 100%);
    transition: all ease-out 1s;
    box-shadow: 0px 10px 40px rgba(199, 45, 146, 0.3);
  }
  @media (max-width: 768px) {
    padding: 15px;
    margin: 20px 10px;
  }
  @media (max-width: 600px) {
    max-width: none;
  }
  @media (max-width: 550px) {
    min-height: 340px;
  }
  @media (max-width: 450px) {
    min-height: 320px;
  }
`;
export const NewsCardImage = styled.img`
  width: 100%;
  height: 100%;
`;
export const NewsCardTitle = styled.span`
  font-size: 20px;
  margin-top: 20px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 20.25px;
  }
`;
export const NewsCardDescription = styled.span`
  font-size: 15px;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.gray};
  line-height: 20.25px;

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 17.55px;
  }
`;
export const NewsCardMoreLink = styled.a`
  color: #486dd3 !important;
`;

export const NewsLink = styled.a``;
