import styled from 'styled-components';
import HelicopterImage from '@/images/Backgrounds/Helicopter.png';

export const IntroContainer = styled.div`
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.white};
  align-items: center;
  position: relative;
`;

export const Helicopter = styled.div<{ mobile: boolean }>`
  background: url(${HelicopterImage});
  width: 700px;
  height: 500px;
  background-size: cover;
  background-position: center;
  position: absolute;
  right: 0;
  top: 100px;
  z-index: -1;
  @media (max-width: 767px) {
    width: 400px;
    height: 400px;
    top: -170px;
  }
  ${(props) => {
    if (!props.mobile) {
      return 'display: none';
    }
    return null;
  }}
`;

export const Rocket = styled.div`
  display: none;
`;
export const IntroTypographySmallHeader = styled.div`
  font-weight: 400;
  font-size: 30px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  text-shadow: 0px 5px 20px rgba(13, 16, 24, 0.3);
  @media (max-width: 767px) {
    display: none;
  }
`;
export const IntroTypographyHeader = styled.h1`
  margin-top: 24px;
  font-size: 80px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  text-align: center;
  line-height: 110%;
  @media (max-width: 787px) {
    font-size: 50px;
    line-height: 56px;
  }
`;

export const IntroCaption = styled.h3`
  margin-top: 25px;
  font-size: 30px;
  line-height: 36px;
  @media (max-width: 787px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  width: 390px;
  justify-content: center;
  & button {
    width: 180px;
  }
  @media (max-width: 767px) {
    justify-content: center;
    & button {
      max-width: 170px;
      margin-left: 10px;
    }
  }
`;

export const ExploreBtnContainer = styled.div`
  margin-top: 142px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    margin-top: 43px;
  }
`;

export const ExploreBtnBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 20px;
  height: 10px;
  cursor: pointer;

  & span,
  &:before,
  &:after {
    position: absolute;
    background-color: ${(props) => props.theme.colors.white};
    height: 1px;
    width: 100%;
    transition: all 0.3s ease 0s;
  }
  &:before,
  &:after {
    content: '';
  }
  &:before {
    transform: rotate(deg);
  }
  & span {
    transform: rotate(90deg);
  }
`;

export const ExploreBtn = styled.span``;

export const ExploreBtnCaption = styled.span`
  margin-top: 15px;
`;

export const CommunityBox = styled.div`
  position: fixed;
  z-index: 10;
  top: 719px;
  right: 50px;
  @media (max-width: 768px) {
    right: 32px;
    top: 775px;
    z-index: 10;
  }
`;

export const SkyBreakersAppLink = styled.a`
  cursor: pointer;
`;

export const SkyBreakersAppImage = styled.img`
  max-height: 66px;
  max-width: 200px;
  margin-top: 24px;
  @media (max-width: 767px) {
    max-width: 120px;
  }
`;

export const SkyBreakersAppLinksBox = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  column-gap: 20px;
`;
