import styled from 'styled-components';

export const PopupBox = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  backdrop-filter: blur(50px);
  z-index: 11;
`;

export const PopupContainer = styled.div`
  z-index: 12;
  max-width: 660px;
  width: 100%;
  max-height: 350px;
  background: #171a22;
  height: 100%;
  border-radius: 30px;
  padding: 60px 0 0 0;
  position: relative;
  @media (max-width: 687px) {
    max-width: 350px;
    max-height: 260px;
    padding: 50px 0 0 0;
  }
`;

export const CloseBtnBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 50px;
  top: 50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  @media (max-width: 687px) {
    width: 30px;
    height: 35px;
    right: 20px;
    top: 10px;
  }
`;

export const CloseBtn = styled.span`
  &:before {
    content: '';
    transform: rotate(45deg);
    height: 2px;
    width: 20px;
    position: absolute;
    background: ${(props) => props.theme.colors.pink};
    @media (max-width: 687px) {
      width: 25px;
    }
  }
  &:after {
    content: '';
    transform: rotate(-45deg);
    height: 2px;
    width: 20px;
    position: absolute;
    background: ${(props) => props.theme.colors.pink};
    z-index: 4;
    @media (max-width: 687px) {
      width: 25px;
    }
  }
`;
