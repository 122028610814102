import styled from 'styled-components';

export const TeamContainer = styled.div``;
export const TeamHeader = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 100px;
  }
`;
export const TeamHeaderTitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;
export const TeamTitleVector = styled.img`
  &:first-child {
    margin-right: 30px;
  }
  &:last-child {
    margin-left: 30px;
  }
  @media (max-width: 768px) {
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
`;
export const TeamHeaderTitle = styled.h1`
  font-size: 80px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 88px;
  @media (max-width: 768px) {
    font-size: 50px;
    line-height: 60px;
  }
`;
export const TeamHeaderDescriptionBox = styled.div`
  max-width: 810px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 40px;
  @media (max-width: 767px) {
    max-width: 350px;
  }
`;
export const TeamHeaderDescription = styled.span`
  font-size: 20px;
  width: 100%;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 20.25px;
  }
`;
export const AchievementsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const AchievementBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 285px;
  width: 100%;
`;

export const Slash = styled.div`
  position: relative;
  top: 5px;
  height: 80px;
  border-right: solid 2px gray;
  transform: rotate(30deg);
  align-self: flex-end;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const AchievementTitle = styled.span`
  background: linear-gradient(270deg, #5766cc 0%, #9c43a8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 60px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
export const AchievementCaption = styled.div`
  font-size: 20px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 20.25px;
  }
`;

export const TeamContent = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 70px;
  @media (max-width: 500px) {
    justify-content: space-evenly;
  }
`;

export const TeamMemberBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 285px;
  width: 100%;
  margin: 92px 11px 0 11px;

  @media (max-width: 768px) {
    width: 170px;
    margin-top: 60px;
    margin-right: 30px;
  }
  @media (max-width: 500px) {
    margin-right: 0;
  }
`;

export const TeamMemberBlockImage = styled.img`
  position: absolute;
  bottom: 190px;
  border-radius: 20px;

  background: linear-gradient(#0a0c13, #0a0c13) padding-box,
    linear-gradient(180deg, #486dd3 0%, #0d1018 100%) border-box;
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
    bottom: 230px;
  }
`;

export const TeamMemberBlockContent = styled.div`
  max-height: 260px;
  height: 260px;
  padding-top: 100px;
  padding-bottom: 55px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: linear-gradient(#0a0c13, #0a0c13) padding-box,
    linear-gradient(180deg, #486dd3 0%, #0d1018 100%) border-box;
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
  border-radius: 20px;
  @media (max-width: 768px) {
    padding: 60px 10px 17px 10px;
    height: 280px;
    max-height: 280px;
  }
`;

export const TeamMemberBlockContentName = styled.span`
  font-size: 20px;
`;

export const LinkedInLink = styled.a`
  margin-left: 10px;
`;
export const LinkedInIcon = styled.img`
  &:hover {
    filter: brightness(1.5);
  }
`;
export const MoreLink = styled.a`
  color: #486dd3;
`;

export const TeamMemberBlockContentProfession = styled.span`
  font-size: 15px;
  margin-top: 5px;
  line-height: 20.25px;
  color: ${(props) => props.theme.colors.gray};
  @media (max-width: 768px) {
    margin-bottom: 17px;
  }
`;

export const TeamMemberBlockContentDescription = styled.span`
  font-size: 15px;
  line-height: 20.25px;
  color: ${(props) => props.theme.colors.gray};
`;

export const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const PopupHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PopupTitle = styled.span`
  font-size: 25px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 687px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const PopupDescription = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.theme.colors.gray};
  margin-top: 15px;
  @media (max-width: 687px) {
    font-size: 15px;
    margin-top: 10px;
    line-height: 20.25px;
  }
`;
