import styled from 'styled-components';

export const AmmunitionTypesContainer = styled.div`
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    margin-top: 50px;
  }
`;

export const AmmunitionTypesHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AmmunitionTypesHeaderTitle = styled.h2`
  font-size: 50px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding: 0 20px;
  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
  }
`;

export const AmmunitionTypesHeaderDescriptionBox = styled.div`
  max-width: 600px;
  text-align: center;
  margin-top: 30px;
  @media (max-width: 767px) {
    margin-top: 20px;
    max-width: 350px;
  }
`;

export const AmmunitionTypesHeaderDescription = styled.span`
  font-size: calc(15px + 5 * (100vw / 1230));
  color: ${(props) => props.theme.colors.white};
`;

export const AmmunitionTypesContent = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1280px) {
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    padding: 0 15px;
  }
`;

export const AmmunitionTypesContentBlock = styled.div`
  display: flex;
  width: 24%;
  box-sizing: border-box;
  flex-direction: column;
  padding: 20px 20px 30px 20px;
  max-width: 285px;
  background: linear-gradient(#0a0c13, #0a0c13) padding-box,
    linear-gradient(180deg, #486dd3 0%, #0d1018 100%) border-box;
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
  border-radius: 20px;
  text-align: center;
  @media (max-width: 900px) {
    padding: 15px;
  }
  @media (max-width: 768px) {
    margin: 10px 5px;
    width: 46%;
    max-width: 200px;
  }
`;

export const AmmunitionTypesContentBlockImage = styled.img`
  background: gray;
  border-radius: 20px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    max-height: 110px;
  }
`;

export const AmmunitionTypesContentBlockTitle = styled.span`
  font-size: 20px;
  margin-top: 25px;
`;
