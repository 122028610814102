import React, { FC, useState } from 'react';
import {
  CheckBox,
  Checked,
} from '@/components/UI/CustomCheckBox/CustomCheckBox.styled';

interface ICheckBoxOption {
  color: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (boolean) => void;
}

const CustomCheckBox: FC<ICheckBoxOption> = ({
  color,
  disabled,
  checked,
  onChange,
}) => {
  const [isChecked, setChecked] = useState<boolean>(checked);
  const handleCheck = () => {
    if (!disabled) {
      setChecked(!isChecked);
      if (onChange) {
        onChange(!isChecked);
      }
    }
  };
  return (
    <CheckBox onClick={handleCheck} color={color}>
      <Checked color={color} checked={isChecked} />
    </CheckBox>
  );
};

export default CustomCheckBox;
