import styled from 'styled-components';
import HelicopterBackgroundImage from '@/images/Backgrounds/HelicopterBackground.png';

export const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Background = styled.div`
  position: relative;
  height: 100%;
`;

export const BackgroundImage = styled.div`
  background: url(${HelicopterBackgroundImage});
  position: absolute;
  height: 1450px;
  width: 100%;
  background-size: cover;
  top: -70px;
  z-index: -2;
  background-position: center;
  @media (max-width: 767px) {
    top: -240px;
    height: 1191px;
  }
`;

export const BackgroundVideo = styled.video`
  position: absolute;
  height: auto;
  width: 100%;
  left: 0;
  top: -130px;
  z-index: -2;

  @media (max-width: 767px) {
    top: -240px;
    height: 1191px;
  }
`;
