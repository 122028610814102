import React from 'react';
import {
  BackgroundContainer,
  BackgroundImage,
  BlackoutFirst,
  BlackoutSecond,
  BlackoutThird,
} from '@/components/RoadmapBackground/RoadmapBackground.styled';
import { useIsMobile } from '@/hooks/useMobile';

const RoadmapBackground = ({ children }) => {
  const isMobile = useIsMobile();

  return (
    <BackgroundContainer>
      <BackgroundImage isMobile={isMobile}>
        <BlackoutFirst />
        <BlackoutSecond />
      </BackgroundImage>
      <BlackoutThird />
      {children}
    </BackgroundContainer>
  );
};

export default RoadmapBackground;
