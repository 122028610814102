import React from 'react';
import Container from '@/components/Container';
import {
  AmmunitionTypesContainer,
  AmmunitionTypesContent,
  AmmunitionTypesContentBlock,
  AmmunitionTypesContentBlockImage,
  AmmunitionTypesContentBlockTitle,
  AmmunitionTypesHeader,
  AmmunitionTypesHeaderDescription,
  AmmunitionTypesHeaderDescriptionBox,
  AmmunitionTypesHeaderTitle,
} from '@/components/UI/AmmunitionTypes/AmmunitionTypes.styled';
import Rocket1 from '@/images/AmmunitionTypes/Machine_guns_245_200.jpg';
import Rocket2 from '@/images/AmmunitionTypes/Rocket_pods_245_200.jpg';
import Rocket3 from '@/images/AmmunitionTypes/Guided_missiles_245_200.jpg';
import Rocket4 from '@/images/AmmunitionTypes/Air_canons_245_200.jpg';

const AmmunitionTypes = () => (
  <Container>
    <AmmunitionTypesContainer>
      <AmmunitionTypesHeader>
        <AmmunitionTypesHeaderTitle>
          70+ powerful ammunitions
        </AmmunitionTypesHeaderTitle>
        <AmmunitionTypesHeaderDescriptionBox>
          <AmmunitionTypesHeaderDescription>
            Select different types of helicopters, configure them by increasing
            their combat capabilities, to accumulate experience for selected
            characters and earn in-game money.
          </AmmunitionTypesHeaderDescription>
        </AmmunitionTypesHeaderDescriptionBox>
      </AmmunitionTypesHeader>
      <AmmunitionTypesContent>
        <AmmunitionTypesContentBlock>
          <AmmunitionTypesContentBlockImage src={Rocket1} />
          <AmmunitionTypesContentBlockTitle>
            Machine Guns
          </AmmunitionTypesContentBlockTitle>
        </AmmunitionTypesContentBlock>
        <AmmunitionTypesContentBlock>
          <AmmunitionTypesContentBlockImage src={Rocket2} />
          <AmmunitionTypesContentBlockTitle>
            Rocket Pods
          </AmmunitionTypesContentBlockTitle>
        </AmmunitionTypesContentBlock>
        <AmmunitionTypesContentBlock>
          <AmmunitionTypesContentBlockImage src={Rocket3} />
          <AmmunitionTypesContentBlockTitle>
            Guided Missiles
          </AmmunitionTypesContentBlockTitle>
        </AmmunitionTypesContentBlock>
        <AmmunitionTypesContentBlock>
          <AmmunitionTypesContentBlockImage src={Rocket4} />
          <AmmunitionTypesContentBlockTitle>
            Air Canons
          </AmmunitionTypesContentBlockTitle>
        </AmmunitionTypesContentBlock>
      </AmmunitionTypesContent>
    </AmmunitionTypesContainer>
  </Container>
);

export default AmmunitionTypes;
