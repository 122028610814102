import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import {
  ControlsBottom,
  ControlsBox,
  Play,
  PlayerWrapper,
  PopupContainer,
  ProgressBar,
  TrailerDescription,
  TrailerPopup,
  TrailerPopupContent,
  TrailerPopupInfo,
  TrailerTitle,
  VolumeBox,
  VolumeControl,
  ProgressBarFill,
  VolumeIcons,
  VolumeIconFirst,
  VolumeIconSecond,
  VolumeControlBox,
  TimeBox,
  FullScreen,
  ProgressBarBox,
  MainProgressBarFill,
  TrailerPopupCaption,
} from '@/components/WatchTrailerPopup/WatchTrailerPopup.styled';
import ReactPlayer from 'react-player';
import VolumeIcon1 from '@/images/CustomPlayer/Volume1.svg';
import VolumeIcon2 from '@/images/CustomPlayer/Volume2.svg';
import fs from '@/images/CustomPlayer/fs.svg';
import { useClickOutside } from '@/hooks/useClickOutside';

interface ITrailerPopupProps {
  isOpened: boolean;
  setOpened: (opened: boolean) => void;
}

const WatchTrailerPopup: FC<ITrailerPopupProps> = ({ isOpened, setOpened }) => {
  const [lastVolumeValue, setLastVolumeValue] = useState<string>('0');
  const [value, setValue] = useState<string>('0');
  const [playing, setPlaying] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(false);
  const [playedSeconds, setPlayedSeconds] = useState<number>(0);
  const [seeking, setSeeking] = useState<boolean>(false);
  const progressBarRef = useRef<HTMLInputElement>(null);
  const playerRef = useRef<ReactPlayer>(null);
  const playerContainerRef = useRef(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [duration, setDuration] = useState<number>();
  const [currentTime, setCurrentTime] = useState<number>();
  useClickOutside(popupRef, () => setOpened(!isOpened));
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    setValue(target.value);
    setLastVolumeValue(target.value);
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpened(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const handlePlaying = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    setValue(progressBarRef.current.value);
    setLastVolumeValue(progressBarRef.current.value);
  }, []);

  const toggleFullScreen = async () => {
    const screenfull = async () =>
      import('screenfull').then((res) =>
        res.default.toggle(playerContainerRef.current),
      );
    screenfull();
    setIsFullScreen(!isFullScreen);
  };

  const handleProgress = (e) => {
    if (e.playedSeconds === e.loadedSeconds) {
      setPlaying(false);
    }
    if (!seeking) {
      setPlayedSeconds(e.played);
    }
  };

  const onMute = () => {
    if (muted) {
      setMuted(false);
      if (Number(lastVolumeValue) > 0) {
        setValue(lastVolumeValue);
        progressBarRef.current.stepUp(Number(lastVolumeValue));
      } else {
        setValue('50');
        setLastVolumeValue('50');
        progressBarRef.current.stepUp(50);
      }
    }
    if (!muted) {
      setMuted(true);
      setValue('0');
      progressBarRef.current.stepDown(Number(lastVolumeValue));
    }
  };

  const onMouseUp = () => {
    setSeeking(false);
  };

  const onSeek = (e) => {
    setPlayedSeconds(e.target.value / 100);
    playerRef.current.seekTo(playedSeconds);
  };

  const onMouseDown = () => {
    setSeeking(true);
  };

  const formatTime = (seconds: number) => {
    const date = new Date(seconds * 1000);
    const mm = date.getUTCMinutes().toString().padStart(2, '0');
    const ss = date.getUTCSeconds().toString().padStart(2, '0');
    return `${mm}:${ss}`;
  };

  useEffect(() => {
    const currentTime = playerRef.current?.getCurrentTime();
    const duration = playerRef.current?.getDuration();
    setCurrentTime(currentTime);
    setDuration(duration);
  }, [playing, playedSeconds]);

  return (
    <PopupContainer>
      <TrailerPopup ref={popupRef}>
        <TrailerPopupContent>
          <PlayerWrapper
            onClick={handlePlaying}
            onDoubleClick={toggleFullScreen}
            ref={playerContainerRef}
          >
            <ReactPlayer
              width="100%"
              height="100%"
              onClick={handlePlaying}
              url="https://youtu.be/onjwulCLFKk&t=1"
              controls={false}
              playing={playing}
              className="customPlayer"
              ref={playerRef}
              onClickPreview={() => handlePlaying}
              volume={Number(value) !== 100 ? Number(`0.${value}`) : 1}
              onProgress={handleProgress}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 0,
                  },
                },
              }}
            />
            <ControlsBox onClick={(e) => e.stopPropagation()}>
              <ProgressBarBox>
                <MainProgressBarFill
                  isFullScreen={isFullScreen}
                  value={(playedSeconds * 100).toString()}
                />
                <ProgressBar
                  onChange={onSeek}
                  onClick={onSeek}
                  onMouseDown={onMouseDown}
                  onMouseUp={onMouseUp}
                  max={100}
                  min={0}
                  value={playedSeconds * 100}
                  isFullScreen={isFullScreen}
                  className="range"
                  type="range"
                  style={{ cursor: 'pointer' }}
                />
              </ProgressBarBox>
              <ControlsBottom>
                <Play onClick={handlePlaying} playing={playing} />
                <VolumeBox>
                  <VolumeIcons value={value} onClick={onMute}>
                    <VolumeIconFirst src={VolumeIcon1} />
                    <VolumeIconSecond value={value} src={VolumeIcon2} />
                  </VolumeIcons>
                  <VolumeControlBox>
                    <VolumeControl
                      max={100}
                      ref={progressBarRef}
                      onChange={handleInputChange}
                      type="range"
                    />
                    <ProgressBarFill value={value} />
                  </VolumeControlBox>
                </VolumeBox>
                <TimeBox>
                  {(currentTime && formatTime(currentTime)) || '00:00'} /{' '}
                  {duration && formatTime(duration)}
                </TimeBox>
                <FullScreen src={fs} onClick={toggleFullScreen} />
              </ControlsBottom>
            </ControlsBox>
          </PlayerWrapper>
          <TrailerPopupInfo>
            <TrailerTitle>SkyBreakers</TrailerTitle>
            <TrailerDescription>
              First play-and-earn arcade helicopter simulator
            </TrailerDescription>
          </TrailerPopupInfo>
        </TrailerPopupContent>
      </TrailerPopup>
      <TrailerPopupCaption>
        Press ESC or click anywhere on the screen to exit the player.
      </TrailerPopupCaption>
    </PopupContainer>
  );
};

export default WatchTrailerPopup;
