import React, { useEffect, useState } from 'react';
import {
  ButtonsContainer,
  CommunityBox,
  ExploreBtn,
  ExploreBtnBox,
  ExploreBtnCaption,
  ExploreBtnContainer,
  Helicopter,
  IntroContainer,
  IntroTypographyHeader,
  IntroTypographySmallHeader,
  SkyBreakersAppLink,
  SkyBreakersAppImage,
  SkyBreakersAppLinksBox,
} from '@/components/Intro/Intro.styled';
import Community from '@/components/Community/Community';
import WatchTrailerPopup from '@/components/WatchTrailerPopup/WatchTrailerPopup';
import { useParallax } from 'react-scroll-parallax';
import { useIsMobile } from '@/hooks/useMobile';
import SkyBreakersAppIos from '@/images/SkyBreakersAppIos.png';
import SkyBreakersAppAndroid from '@/images/SkyBreakersAppAndroid.png';
import { ExternalLinks } from '@/utils/constants';
import { Button, ButtonStyleTypes } from '../UI';

const Intro = () => {
  const isMobile = useIsMobile();
  const { ref } = useParallax<HTMLDivElement>({ speed: 40 });
  const [isTrailerPopupOpened, setTrailerPopupOpened] =
    useState<boolean>(false);
  const handleOpen = () => {
    setTrailerPopupOpened(!isTrailerPopupOpened);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isTrailerPopupOpened) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
    }
  }, [isTrailerPopupOpened]);

  return (
    <IntroContainer>
      {isTrailerPopupOpened && (
        <WatchTrailerPopup
          isOpened={isTrailerPopupOpened}
          setOpened={setTrailerPopupOpened}
        />
      )}
      <Helicopter ref={ref} mobile={isMobile} />
      <IntroTypographySmallHeader>
        Welcome to SkyBreakers
      </IntroTypographySmallHeader>
      <SkyBreakersAppLinksBox>
        <SkyBreakersAppLink
          href={ExternalLinks.DOWNLOAD_APP_ANDROID}
          target="_blank"
        >
          <SkyBreakersAppImage src={SkyBreakersAppAndroid} />
        </SkyBreakersAppLink>
        <SkyBreakersAppLink href={ExternalLinks.DOWNLOAD_APP_IOS} target="_blank">
          <SkyBreakersAppImage src={SkyBreakersAppIos} />
        </SkyBreakersAppLink>
      </SkyBreakersAppLinksBox>
      <IntroTypographyHeader>
        ALPHA VERSION LIVE NOW
      </IntroTypographyHeader>
      <ButtonsContainer>
        <Button
          styleType={ButtonStyleTypes.INVERTED}
          width="180px"
          onClick={handleOpen}
        >
          Watch Trailer
        </Button>
      </ButtonsContainer>
      <ExploreBtnContainer>
        <ExploreBtnBox>
          <ExploreBtn />
        </ExploreBtnBox>
        <ExploreBtnCaption>Scroll down to explore</ExploreBtnCaption>
      </ExploreBtnContainer>
      <CommunityBox>
        <Community />
      </CommunityBox>
    </IntroContainer>
  );
};

export default Intro;
