import React, { useEffect, useState } from 'react';
import {
  ArrowBox,
  CommunityArrow,
  CommunityBox,
  CommunityBtnImage,
  CommunityBtnList,
  CommunityBtnListItem,
  CommunityBtnSubscribersCount,
  CommunityLink,
  DiscordBox,
  TelegramBox,
  TwitterBox,
} from '@/components/Community/Community.styled';
import Discord from '@/images/Community/Discord.svg';
import Telegram from '@/images/Community/Telegram.svg';
import Twitter from '@/images/Community/Twitter.svg';
import Facebook from '@/images/Community/Facebook.svg';
import Instagram from '@/images/Community/Instagram.svg';
import Line from '@/images/Community/Line.svg';
import Snapchat from '@/images/Community/Snapchat.svg';
import Medium from '@/images/Community/Medium.svg';
import { SocialLinks } from '@/utils/constants';
import { formatSocialUsersCount } from '@/utils/numbers';
import { useIsMobile } from '@/hooks/useMobile';
import { useAppSelector } from '@/hooks/redux';

const Community = () => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { socialInfo } = useAppSelector((state) => state.socialReducer);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      setExpanded(false);
    }
  }, []);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleCommunity = (link: string) => {
    if (typeof window !== 'undefined') {
      const a = document.createElement('a');
      a.setAttribute('href', link);
      a.setAttribute('target', '_blank');
      a.click();
      a.remove();
    }
  };

  return (
    <CommunityBox expanded={expanded} onClick={handleExpanded}>
      {expanded && (
        <>
          <DiscordBox>
            <CommunityBtnImage expanded={expanded} src={Discord} />
          </DiscordBox>
          <TwitterBox>
            <CommunityBtnImage expanded={expanded} src={Twitter} />
          </TwitterBox>
          <TelegramBox>
            <CommunityBtnImage expanded={expanded} src={Telegram} />
          </TelegramBox>
        </>
      )}
      <CommunityBtnList expanded={expanded}>
        <CommunityBtnListItem
          onClick={() => handleCommunity(SocialLinks.DISCORD)}
        >
          <CommunityBtnSubscribersCount>
            {formatSocialUsersCount(socialInfo.discord)}
          </CommunityBtnSubscribersCount>
          <CommunityLink>
            <CommunityBtnImage expanded={expanded} src={Discord} />
          </CommunityLink>
        </CommunityBtnListItem>
        <CommunityBtnListItem
          onClick={() => handleCommunity(SocialLinks.TELEGRAM)}
        >
          <CommunityBtnSubscribersCount>
            {formatSocialUsersCount(socialInfo.telegram)}
          </CommunityBtnSubscribersCount>
          <CommunityLink>
            <CommunityBtnImage expanded={expanded} src={Telegram} />
          </CommunityLink>
        </CommunityBtnListItem>
        <CommunityBtnListItem
          onClick={() => handleCommunity(SocialLinks.TWITTER)}
        >
          <CommunityBtnSubscribersCount>
            {formatSocialUsersCount(socialInfo.twitter)}
          </CommunityBtnSubscribersCount>
          <CommunityLink target="_blank" href={SocialLinks.TWITTER}>
            <CommunityBtnImage expanded={expanded} src={Twitter} />
          </CommunityLink>
        </CommunityBtnListItem>
        {!isMobile && (
          <>
            <CommunityBtnListItem
              onClick={() => handleCommunity(SocialLinks.FACEBOOK)}
            >
              <CommunityBtnSubscribersCount>
                {formatSocialUsersCount(socialInfo.facebook)}
              </CommunityBtnSubscribersCount>
              <CommunityLink target="_blank" href={SocialLinks.FACEBOOK}>
                <CommunityBtnImage expanded={expanded} src={Facebook} />
              </CommunityLink>
            </CommunityBtnListItem>
            <CommunityBtnListItem
              onClick={() => handleCommunity(SocialLinks.INSTAGRAM)}
            >
              <CommunityLink target="_blank" href={SocialLinks.INSTAGRAM}>
                <CommunityBtnImage expanded={expanded} src={Instagram} />
              </CommunityLink>
            </CommunityBtnListItem>
            <CommunityBtnListItem
              onClick={() => handleCommunity(SocialLinks.SNAPCHAT)}
            >
              <CommunityLink target="_blank" href={SocialLinks.SNAPCHAT}>
                <CommunityBtnImage expanded={expanded} src={Snapchat} />
              </CommunityLink>
            </CommunityBtnListItem>
            <CommunityBtnListItem
              onClick={() => handleCommunity(SocialLinks.MEDIUM)}
            >
              <CommunityLink target="_blank" href={SocialLinks.MEDIUM}>
                <CommunityBtnImage expanded={expanded} src={Medium} />
              </CommunityLink>
            </CommunityBtnListItem>
            <CommunityBtnListItem
              onClick={() => handleCommunity(SocialLinks.LINE)}
            >
              <CommunityLink target="_blank" href={SocialLinks.LINE}>
                <CommunityBtnImage expanded={expanded} src={Line} />
              </CommunityLink>
            </CommunityBtnListItem>
          </>
        )}
      </CommunityBtnList>
      <ArrowBox onClick={handleExpanded}>
        <CommunityArrow expanded={expanded} />
      </ArrowBox>
    </CommunityBox>
  );
};

export default Community;
