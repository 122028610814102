import styled from 'styled-components';
import checkBoxBorder from '@/images/CheckBox/CheckBoxBorder.svg';
import Check from '@/images/CheckBox/Check.svg';
import CheckBlue from '@/images/CheckBox/CheckBlue.svg';
import checkBoxBorderBlue from '@/images/CheckBox/CheckBoxBorderBlue.svg';

interface IChecked {
  checked: boolean;
}

interface IColor {
  color: string;
}

export const Checked = styled.span`
  width: 9px;
  height: 7.9px;
  background: url(${(props: IChecked & IColor) =>
    props.checked ? (props.color === 'pink' ? Check : CheckBlue) : ''});
`;

export const CheckInput = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
`;

export const CheckBox = styled.div`
  position: relative;
  width: 1em;
  height: 18.4px;
  background: url(${(props: IColor) =>
    props.color === 'pink' ? checkBoxBorder : checkBoxBorderBlue});
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    filter: brightness(1.5);
  }
`;
