import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  width: 100%;
`;

export const HelicoptersBox = styled.div`
  margin-top: 200px;
  color: white;
  transition: all 0.5s ease-out;
  @media (max-width: 767px) {
    margin-top: 100px;
  }
`;
export const HelicoptersHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HelicoptersHeaderTitle = styled.h1`
  font-size: 80px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  line-height: 88px;
  @media (max-width: 767px) {
    font-size: 50px;
    line-height: 60px;
  }
`;

export const DescriptionBox = styled.div`
  margin-top: 40px;
  max-width: 600px;
  text-align: center;
  @media (max-width: 767px) {
    max-width: 350px;
  }
`;

export const HelicoptersHeaderDescription = styled.span`
  font-size: 20px;
  color: ${(props) => props.theme.colors.white};
  line-height: 24px;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20.25px;
  }
`;

export const HelicoptersContent = styled.div`
  max-width: 1230px;
  width: 90%;
  margin: 0 auto;
  @media (max-width: 855px) {
    display: none;
  }
`;

export const HelicoptersContentMobile = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 855px) {
    display: flex;
  }
`;

export const SliderBlockImage = styled.img`
  width: 100%;
  max-height: 200px;
`;

export const SliderBlockTitle = styled.span`
  font-size: 20px;
  margin-top: 20px;
  text-align: left;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20.25px;
    margin-top: 10px;
  }
`;

export const SliderBlockDescription = styled.span`
  font-size: 15px;
  margin-top: 10px;
  padding: 0;
  color: ${(props) => props.theme.colors.gray};
  line-height: 20.25px;
  text-align: left;
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 17.55px;
    margin-top: 5px;
  }
`;

export const ComingSoonBtn = styled.button`
  display: none;
  max-width: 350px;
  width: 100%;
  height: 50px;
  background: ${(props) => props.theme.colors.pink};
  border-radius: 5px;
  margin-top: 15px;
  &:hover {
    font-size: 19px;
    filter: brightness(1.15);
  }
`;

export const SliderBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const SliderContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-color: #0a0c13;
  width: 100%;
  z-index: 9;
`;

export const SliderBlock = styled.div`
  transition: none;
  max-width: 380px;
  margin: 40px 10px;
  padding: 20px 20px 30px 20px;
  display: flex;
  min-height: 344px;
  flex-direction: column;
  position: relative;
  background: linear-gradient(#0a0c13, #0a0c13) padding-box,
  linear-gradient(180deg, #486dd3 0%, #0d1018 100%) border-box;
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
  border-radius: 20px;
  cursor: pointer;
  @media(max-width: 850px) {
    height: auto;
    min-height: 344px;
  }
  &:hover {
    background: linear-gradient(#0a0c13, #0a0c13) padding-box,
    linear-gradient(270deg, #5766cc 0%, #9c43a8 100%);
    box-shadow: 0px 10px 40px rgba(199, 45, 146, 0.3);
    justify-content: flex-end;
    & ${SliderBlockImage} {
    position: absolute;
      left: 20px;
      top: 20px;
      width: calc(100% - 40px);
    }
    & ${ComingSoonBtn} {
   display: block;
    }
  }
  }
`;

export const HelipediaLink = styled.a``;
