const settings = {
  slidesToShow: 3,
  slidesToScroll: 3,
  className: 'news-slider',
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 730,
      settings: {
        centerMode: true,
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 420,
      settings: {
        infinite: false,
        centerMode: true,
        slidesToShow: 0.88,
      },
    },
  ],
};
export default settings;
