const items = [
  {
    title: '2024 Q1',
    bottom: false,
    checkboxes: [
      { label: 'SkyBreakers game tuning', checked: true },
      { label: 'Arcade control adaptation', checked: true },
      { label: 'Community 40k+', checked: true },
      { label: 'Multi language support', checked: true },
    ],
    color: 'pink',
    colorMobile: 'pink',
  },
  {
    title: '2024 Q2',
    bottom: false,
    checkboxes: [
      { label: 'CN, JP languages suppor', checked: false },
      { label: 'Helicopter NFT development', checked: false },
      { label: 'TGE (DEX/CEX listing)', checked: false },
      { label: 'Live ops support', checked: false },
      { label: 'Community 50k+', checked: false },
    ],
    color: 'blue',
    colorMobile: 'blue',
  },
  {
    title: '2024 Q3',
    bottom: false,
    checkboxes: [
      { label: 'Staking program', checked: false },
      { label: 'NFT market integration', checked: false },
      { label: '1st Grand Trournament', checked: false },
      { label: 'Community 100k+', checked: false },
    ],
    color: 'pink',
    colorMobile: 'pink',
  },
  {
    title: '2024 Q4',
    bottom: true,
    checkboxes: [
      { label: 'Official game launch (AR +VR)', checked: false },
      { label: 'New maps, new lvl 6 & 7 helicopters', checked: false },
      { label: 'Clans, leaderboard', checked: false },
      { label: 'PVE Additional Content', checked: false },
      { label: 'Community 200k+', checked: false },
    ],
    color: 'blue',
    colorMobile: 'blue',
  },
];

export default items;
