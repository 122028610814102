import styled from 'styled-components';

export const GameplayContainer = styled.div`
  color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 100px;
  }
`;

export const GameplayHeader = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
`;

export const GameplayHeaderImage = styled.img`
  position: absolute;
  & :first-child {
    left: -425px;
    top: 40px;
  }
  &:last-child {
    right: -425px;
    top: 40px;
  }
`;

export const GameplayHeaderTypography = styled.h1`
  font-size: 80px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 88px;
  @media (max-width: 768px) {
    font-size: 50px;
    line-height: 60px;
  }
`;

export const GameplayHeaderDescriptionBox = styled.div`
  max-width: 810px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  margin-top: 30px;
  @media (max-width: 767px) {
    max-width: 350px;
  }
`;
export const GameplayHeaderDescription = styled.span`
  font-size: 20px;
  width: 100%;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 20.25px;
  }
`;

export const GameplayContent = styled.div`
  display: flex;
  margin-top: 50px;
  max-width: 1230px;
  width: 100%;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
`;

export const PlayerWrapper = styled.div`
  position: relative;
  max-height: 350px;
  border-radius: 20px;
  height: 100%;
  width: 100%;
  display: flex;
`;

export const GameplayContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 20px;
  max-width: 600px;
  height: 350px;
  width: 100%;

  &:first-child {
    margin-right: 15px;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  &:last-child {
    margin-left: 15px;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  @media (max-width: 768px) {
    max-width: 330px;
    max-height: 200px;
    margin-top: 20px;
  }
  & .video {
    border-radius: inherit;

    height: 100%;
    width: 100%;
    @media (max-width: 768px) {
      max-width: 330px;
      max-height: 180px;
    }
  }
  & .react-player__preview {
    border-radius: 20px;
    height: 100%;
    width: 100%;
    position: relative;
  }
  & .react-player__shadow {
  }
  & .react-player__playIcon {
    border-radius: 15px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    flex-direction: column;
    justify-content: center;
  }
`;

export const GameplayInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GameplayDescriptionBox = styled.div`
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const GameplayName = styled.span`
  font-size: 20px;
  line-height: 24px;
  margin: 10px 20px 0 0px;

  @media (max-width: 768px) {
    line-height: 20.25px;
    font-size: 15px;
  }
`;

export const GameplayDescription = styled.span`
  margin: 10px 5px 0 10px;
  font-size: 15px;
  color: ${(props) => props.theme.colors.gray};
`;

export const VideoTime = styled.span`
  font-size: 15px;
  position: absolute;
  bottom: 0;
  right: 0px;
  background: rgba(23, 26, 34, 0.5);
  padding: 5px 17.5px;
  border-radius: 10px 0 0 0;

  @media (max-width: 768px) {
    bottom: 2px;
    padding: 1px 14.5px;
    border-radius: 10px 0 12px 0;
  }
`;
