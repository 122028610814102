import React from 'react';
import {
  BackgroundContainer,
  BackgroundImage,
  BackgroundVideo,
} from '@/components/HelicopterBackground/HelicopterBackground.styled';
import HelicopterBackgroundImage from '@/images/Backgrounds/HelicopterBackgroundVideo.mp4';
import { useIsMobile } from '@/hooks/useMobile';

const HelicopterBackground = ({ children }) => {
  const isMobile = useIsMobile();
  return (
    <BackgroundContainer>
      {children}
      {!isMobile ? (
        <BackgroundVideo
          width="100%"
          src={HelicopterBackgroundImage}
          playsInline
          autoPlay
          loop
          muted
        >
          <source src={HelicopterBackgroundImage} type="video.mp4" />
        </BackgroundVideo>
      ) : (
        <BackgroundImage />
      )}
    </BackgroundContainer>
  );
};

export default HelicopterBackground;
