import React, { useState } from 'react';
import {
  ProjectContainer,
  ProjectContent,
  ProjectHeader,
  ProjectHeaderTitle,
  Slide,
  SlideDescription,
  SlideInfo,
  SlideTitle,
} from '@/components/Projects/Project.styled';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import PlayIcon from '@/components/Gameplay/PlayIcon/PlayIcon';
import './video.css';

const items = [
  {
    url: 'https://youtu.be/EGUdowtiAFs',
    title: 'Space Punks | AAA Project',
    description:
      ' Space Punks is a living, evolving, online co-op action RPG space romp that will see you shootin’ and lootin’ your way across the galaxy. ',
  },
  {
    url: 'https://youtu.be/qhAjTUiHOQU&t=5s',
    title: 'Rift | AAA Project',
    description:
      ' RIFT is a massively multiplayer online adventure (MMORPG) set in the dynamic fantasy universe of Telara.',
  },
  {
    url: 'https://youtu.be/00d8nw3eG18&t=2s',
    title: 'Shadow Warrior 3 | AAA Project',
    description:
      ' Shadow Warrior 3 is the offbeat first-person shooter series to the next level with a seamless blend of fast-paced gunplay, razor-sharp melee combat, and a spectacular free-running movement system.',
  },
];

const Projects = () => {
  const [pauses, setPauses] = useState<boolean[]>(items.map(() => false));
  const handlePauseChange = (event: number) => {
    const newPauses = [...pauses];

    newPauses[event] = !pauses[event];
    setPauses(newPauses);
  };

  const settings = {
    slidesToShow: 1,
    className: 'slider-div',
    centerMode: true,
    centerPadding: '25%',
    infinite: false,
    dots: true,
    infinite: true,
    dotsClass: 'dots',
    adaptiveWidth: true,
    // eslint-disable-next-line react/no-unstable-nested-components
    appendDots: (dots) => <ul>{dots}</ul>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          centerPadding: '39.9%',
        },
      },
    ],
  };

  return (
    <ProjectContainer>
      <ProjectHeader>
        <ProjectHeaderTitle>projects we worked on</ProjectHeaderTitle>
      </ProjectHeader>
      <ProjectContent>
        <Slider {...settings} className="video-slider">
          {items.map((item, idx) => (
            <Slide className="Slide" key={idx}>
              <ReactPlayer
                url={item.url}
                className="video"
                controls
                playIcon={<PlayIcon />}
                light
                width="100%"
                height="100%"
                onClickPreview={() => handlePauseChange(idx)}
              />
              {!pauses[idx] && (
                <SlideInfo className="info">
                  <SlideTitle className="Slide-title">{item.title}</SlideTitle>
                  <SlideDescription className="slide-description">
                    {item.description}
                  </SlideDescription>
                </SlideInfo>
              )}
            </Slide>
          ))}
        </Slider>
      </ProjectContent>
    </ProjectContainer>
  );
};

export default Projects;
